.dropzone {
  border-radius: 4px;
  border: 1px solid #aaa;
  height: 100px;
  width: 100%;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  margin-bottom: 24px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}

.dropzoneContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imagePreview {
  max-height: 250px;
  object-fit: contain;
}

.dropzoneImage {
  border-radius: 4px;
  border: 1px solid #aaa;
  height: 100px;
  width: 100%;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}

/* Re-order list styles start */
ul,
.item {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reorderGroup {
  position: relative;
  width: 300px;
}

.item {
  border-radius: 10px;
  /* margin-bottom: 10px; */
  width: 100%;
  padding: 8px;
  position: relative;
  background: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

li svg {
  width: 18px;
  height: 18px;
  cursor: grab;
}
/* Re-order list styles e */
