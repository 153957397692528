.logo {
  -webkit-filter: drop-shadow(1px 1px 1px #222);
  filter: drop-shadow(1px 1px 1px #222);
  margin-bottom: 16px;
}

.background {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 0;
}

.slanted {
  background-color: #4b5b92;
  height: 250px;
  z-index: 0;
  -webkit-clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
  margin-bottom: -1px;
}

.bgRect {
  width: 100%;
  background-color: #4b5b92;
  height: 250px;
}
