.container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  padding: 20px;
}

@media (max-width: 920px) {
  .container {
    display: flex;
    flex-direction: column;
  }
}
